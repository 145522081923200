import { axios } from '@/utils/request'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 *
 * @param parameter
 * @returns {*}
 */
export function projectList(parameter) {
  return axios({
    url: '/org/tbProject/public/projects',
    method: 'get',
    params: parameter
  })
}

// 需求列表
export function listProject(parameter) {
  return axios({
    url: '/org/tbProject/public/projects',
    method: 'get',
    params: parameter
  })
}

// 查看详情
export function getDetail(projectId, params) {
  return axios({
    url: '/org/tbProject/findProjectDetails/' + projectId,
    method: 'get',
    params
  })
}

// 查看详情
export function getDetailForPublic(projectId, params) {
  return axios({
    url: '/org/tbProject/public/projects/' + projectId,
    method: 'get',
    params
  })
}

// 添加/修改
export function addOrEdit(data) {
  return axios({
    url: '/org/tbProject/saveProject',
    method: 'post',
    data
  })
}

// 我的需求列表
export function listMyProject(params) {
  return axios({
    url: '/org/tbProject/listMyProject',
    method: 'get',
    params
  })
}

// 抢单入驻机构
export function listMarksOrg(params) {
  return axios({
    url: '/operation/tbProjectOffer/projects/marks/orgs',
    method: 'get',
    params
  })
}

// 结束需求
export function toFinish(data) {
  return axios({
    url: '/org/tbProject/actions/finish',
    method: 'post',
    data
  })
}

// 需求合作
export function toCooperation(data) {
  return axios({
    url: '/operation/tbProjectOffer/projects/actions/cooperation',
    method: 'post',
    data
  })
}

// 需求不合作
export function toNoCooperation(data) {
  return axios({
    url: '/operation/tbProjectOffer/projects/actions/noncooperation',
    method: 'post',
    data
  })
}

// 获取合作状态
export function getMarksStatus(params) {
  return axios({
    url: '/operation/tbProjectOffer/projects/marks/status',
    method: 'get',
    params
  })
}

// 抢单
export function toOffer(data) {
  return axios({
    url: '/operation/tbProjectOffer/projects/actions/offer',
    method: 'post',
    data
  })
}
