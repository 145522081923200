<template>
  <div class="wrap">
    <div class="card">
      <mt-field label="需求名称" placeholder="请输入需求名称" v-model="title"></mt-field>
    </div>

    <div class="card">
      <mt-field readonly label="需求类型" disableClear class="displaynone"></mt-field>
      <mt-checklist v-model="typeDesc" @change="checklistClick" :options="typeArr" align="right"> </mt-checklist>
    </div>

    <div class="card" @click="daysVisible = true">
      <mt-field readonly label="截止时间" disableClear placeholder="请选择截止时间" v-model="daysDesc"></mt-field>
    </div>

    <div class="card card-column">
      <mt-field label="需求简介" placeholder="请输入" type="textarea" rows="4" v-model="content"> </mt-field>
    </div>
    <div class="card">
      <div class="card-head">
        上传附件
        <a href="javascript:;" class="upload-btn">
          <img src="@/assets/img/project/icon_upload@2x.png" alt="" />
          <span>点击上传</span>
          <input type="file" @change="onFileChange" />
        </a>
      </div>
      <div class="card-body">
        <ul class="attachment-files-img">
          <li v-for="(item, index) in customFileList.imglist" class="file-img-wrapper" :key="index">
            <img :src="item.url" alt="" class="file-img-item" />
            <img
              @click="onRemoveFile(index)"
              src="@/assets/img/information/icon_close.png"
              class="file-img-icon-remove"
              alt=""
            />
          </li>
        </ul>
        <ul class="attachment-files">
          <li v-for="(item, index) in customFileList.downloadlist" :key="index">
            <a :href="item.url" target="_blank">
              <span>{{ item.name }}</span>
            </a>
            <img @click="onRemoveFile(index)" src="@/assets/img/information/icon_close.png" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <mt-button @click="onCancel">取消</mt-button>
      <mt-button :loading="submiting" @click="onSubmit" type="primary">{{ isEdit ? '更新' : '提交' }}</mt-button>
    </div>
    <mt-popup v-model="daysVisible" position="bottom">
      <div class="toolbar">
        <mt-button size="small" @click="onClose">取消</mt-button>
        <mt-button size="small" @click="onConfirmDays" type="primary">确定</mt-button>
      </div>
      <mt-picker :showToolbar="true" :slots="daysSlots" @change="onDaysChange"></mt-picker>
    </mt-popup>
  </div>
</template>
<script>
import { getDetail, addOrEdit } from '@/api/project'
import { Toast, Indicator, MessageBox, Checklist } from 'mint-ui'
import moment from 'moment'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { getCookie } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

export default {
  data() {
    const { projectId } = this.$route.params
    return {
      projectId,
      typeList: [],
      dayList: [
        { title: '3天', value: 3 },
        { title: '7天', value: 7 },
        { title: '15天', value: 15 },
        { title: '30天', value: 30 }
      ],
      fileList: [],
      baseURL: process.env.VUE_APP_API_BASE_URL,
      isEdit: false,
      submiting: false,
      title: '',
      typeValue: '',
      days: '',
      typeDesc: [],
      daysDesc: '',
      content: '',
      typeVisible: false,
      daysVisible: false,
      typeArr: []
    }
  },
  computed: {
    ...mapGetters(['type', 'id', 'uiCacheDbDictData']),
    daysSlots() {
      return [
        {
          flex: 1,
          values: this.dayList.map(item => item.title)
        }
      ]
    },
    customFileList() {
      const imglist = []
      const downloadlist = []
      this.fileList.map(item => {
        const fileType = item.url.split('.').pop()
        if (['png', 'jpeg', 'gift', 'webp', 'jpg'].indexOf(fileType) > -1) {
          imglist.push(item)
        } else {
          downloadlist.push(item)
        }
      })
      return {
        imglist,
        downloadlist
      }
    }
  },
  components: {
    Checklist
  },
  mounted() {
    console.log(this.id)
    if (this.projectId !== '0') {
      this.isEdit = true
      this.getDetail()
    }
    this.loadConfig()
  },
  methods: {
    checklistClick(val) {
      console.log(val)
      this.typeValue = val.toString()
    },
    /**
     * 加载需求类型列表
     */
    loadConfig(type) {
      // const { respCode, data } = await getType({
      //   type: { personal: 'personal', company: 'company', org: '' }[this.type],
      //   id: this.id
      // })
      // if (respCode === 0) {
      //   this.typeList = data.map((item) => {
      //     this.typeArr.push(item.code)
      //   })
      // }

      const that = this
      const dictList = that.uiCacheDbDictData['project_type']
      const personal = ['2', '4', '6', '8', '10']

      const companyList = dictList.filter(item => !personal.includes(item.value))
      const personalList = dictList.filter(item => personal.includes(item.value))

      that.typeArr = { personal: personalList, company: companyList, org: dictList }[that.type]
    },

    async getDetail() {
      const { respCode, data } = await getDetail(this.projectId)
      if (respCode === 0) {
        const days = moment(data.endTime).diff(moment(data.createTime), 'day')

        this.title = data.title
        this.content = data.content || ''
        this.typeDesc = data.type.split(',')
        this.typeValue = this.typeDesc
        console.log(this.typeDesc)
        this.days = days
        this.daysDesc = `${days}天`

        if (data.attachments) {
          this.fileList = data.attachments.split(';').map((str, index) => {
            const arr = str.split(',')
            return {
              status: 'done',
              uid: index,
              name: arr[0],
              url: arr[1],
              response: {
                name: arr[0],
                url: arr[1]
              }
            }
          })
        }
      }
    },

    onTypeChange(ev) {
      console.log(ev)
      this.tmpTypeDesc = ev.getValues()[0]
    },

    onDaysChange(ev) {
      this.tmpDaysDesc = ev.getValues()[0]
    },

    async onFileChange(ev) {
      const that = this
      if (ev.target.files && ev.target.files.length) {
        Indicator.open()
        try {
          const token = getCookie(ACCESS_TOKEN)
          const fd = new FormData()
          fd.append('file', ev.target.files[0])
          const res = await axios({
            method: 'POST',
            headers: {
              'X-Access-Token': token
            },
            url: that.baseURL + '/jeecg-boot/sys/common/upload',
            data: fd
          })
          if (res.data.success) {
            that.fileList.push({
              status: 'done',
              name: ev.target.files[0].name,
              url: res.data.message,
              response: res.data
            })
          }
        } catch (error) {
          if (error.response && error.response.data) {
            const { respMsg } = error.response.data
            Toast(respMsg || '上传失败')
          } else {
            Toast('上传失败')
          }
        } finally {
          Indicator.close()
        }
      }
    },

    onRemoveFile(index) {
      this.fileList.splice(index, 1)
    },

    // 确认天数的选择
    onConfirmDays() {
      if (this.tmpDaysDesc) {
        this.daysDesc = this.tmpDaysDesc
        this.days = this.dayList[this.daysSlots[0].values.indexOf(this.tmpDaysDesc)].value
        this.tmpDaysDesc = ''
      }
      this.onClose()
    },

    async onSubmit() {
      if (!this.title) {
        Toast('请输入需求名称')
        return
      }
      if (!this.typeValue) {
        Toast('请选择需求类型')
        return
      }
      if (!this.days) {
        Toast('请选择需求截止时间')
        return
      }
      // if (!this.content) {
      //   Toast('请输入需求简介')
      //   return
      // }
      const fd = new FormData()
      if (this.isEdit) {
        fd.append('id', this.projectId)
      }
      fd.append('title', this.title)
      if (this.content) {
        fd.append('content', this.content)
      }
      fd.append('days', this.days)
      fd.append('type', this.typeValue)
      fd.append(
        'attachments',
        this.fileList
          .map(item => {
            return `${item.url}`
          })
          .join(',')
      )

      const __params = {}

      for (const [k, v] of fd.entries()) {
        __params[k] = v
      }

      try {
        this.submiting = true
        await addOrEdit(__params)
        Toast(this.isEdit ? '更新成功' : '提交成功')
        this.$router.push('/admin/project')
        // if (!this.isEdit) {
        //   this.fileList = []
        //   this.title = ''
        //   this.content = ''
        //   this.typeDesc = []
        //   this.daysDesc = ''
        //   this.typeValue = ''
        //   this.days = ''
        //   this.$router.back()
        // }
      } catch (error) {
        // Toast('请求失败')
      } finally {
        this.submiting = false
      }
    },
    onCancel() {
      MessageBox({
        title: '提示',
        message: '是否确认退出需求编辑?',
        showCancelButton: true
      }).then(action => {
        if (action === 'confirm') {
          this.$router.back()
        }
      })
    },
    onClose() {
      this.typeVisible = false
      this.daysVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 10px 15px;

  .card {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 2px 0px rgba(23, 140, 249, 0.1);
    border-radius: 6px;
    margin-bottom: 15px;
    overflow: hidden;

    &-head {
      padding: 15px;
      position: relative;
    }
    &-body {
      padding: 15px;
      padding-top: 0;
    }

    &-column {
      /deep/ .mint-cell-wrapper {
        display: flex;
        flex-direction: column;
        padding: 5px 15px;
        padding-bottom: 10px;
        .mint-cell-title {
          width: 100%;
        }
        .mint-cell-value {
          width: 100%;
          textarea {
            background: rgba(242, 242, 242, 1);
            border-radius: 2px;
            padding: 15px;
          }
        }
      }
    }

    .remove_img{
      position: absolute;
    }

    a:hover {
      color: #333333;
    }
    /deep/ .mint-cell {
      &:first-child {
        .mint-cell-wrapper {
          background-image: none;
        }
      }
      &:last-child {
        background-image: none;
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0 30px 0;
    .mint-button {
      width: 40%;
      margin: 0 10px;
      border-radius: 41px;
    }
  }
  /deep/ .picker {
    width: 100vw;
  }
  .toolbar {
    width: 100vw;
    display: flex;
    height: 50px;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
    border: 1px solid #f5f5f5;
  }
  .upload-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 15px;
    input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      opacity: 0;
    }
    img {
      width: 15px;
      height: 15px;
    }
    span {
      color: #178cf9;
      font-size: 15px;
      margin-left: 10px;
    }
  }
  .attachment-files {
    margin-top: 0;
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 0;
      a {
        font-size: 14px;
        color: #178cf9;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        width: 0;
      }
      img {
        flex-shrink: 0;
        margin-left: 5px;
      }
    }
  }

  .attachment-files-img {
    display: flex;
    flex-wrap: wrap;
    li.file-img-wrapper {
      position: relative;
      width: 30%;
      margin-right: 3.33%;
      margin-bottom: 5%;
      border-radius: 4px;
      .file-img-item {
        width: 100%;
      }
      .file-img-icon-remove {
        position: absolute;
        top: -7px;
        right: -7px;
      }
    }
  }
}
</style>
