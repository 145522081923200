var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("mt-field", {
            attrs: { label: "需求名称", placeholder: "请输入需求名称" },
            model: {
              value: _vm.title,
              callback: function($$v) {
                _vm.title = $$v
              },
              expression: "title"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("mt-field", {
            staticClass: "displaynone",
            attrs: { readonly: "", label: "需求类型", disableClear: "" }
          }),
          _c("mt-checklist", {
            attrs: { options: _vm.typeArr, align: "right" },
            on: { change: _vm.checklistClick },
            model: {
              value: _vm.typeDesc,
              callback: function($$v) {
                _vm.typeDesc = $$v
              },
              expression: "typeDesc"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card",
          on: {
            click: function($event) {
              _vm.daysVisible = true
            }
          }
        },
        [
          _c("mt-field", {
            attrs: {
              readonly: "",
              label: "截止时间",
              disableClear: "",
              placeholder: "请选择截止时间"
            },
            model: {
              value: _vm.daysDesc,
              callback: function($$v) {
                _vm.daysDesc = $$v
              },
              expression: "daysDesc"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card card-column" },
        [
          _c("mt-field", {
            attrs: {
              label: "需求简介",
              placeholder: "请输入",
              type: "textarea",
              rows: "4"
            },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = $$v
              },
              expression: "content"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-head" }, [
          _vm._v(" 上传附件 "),
          _c(
            "a",
            { staticClass: "upload-btn", attrs: { href: "javascript:;" } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/project/icon_upload@2x.png"),
                  alt: ""
                }
              }),
              _c("span", [_vm._v("点击上传")]),
              _c("input", {
                attrs: { type: "file" },
                on: { change: _vm.onFileChange }
              })
            ]
          )
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "ul",
            { staticClass: "attachment-files-img" },
            _vm._l(_vm.customFileList.imglist, function(item, index) {
              return _c("li", { key: index, staticClass: "file-img-wrapper" }, [
                _c("img", {
                  staticClass: "file-img-item",
                  attrs: { src: item.url, alt: "" }
                }),
                _c("img", {
                  staticClass: "file-img-icon-remove",
                  attrs: {
                    src: require("@/assets/img/information/icon_close.png"),
                    alt: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.onRemoveFile(index)
                    }
                  }
                })
              ])
            }),
            0
          ),
          _c(
            "ul",
            { staticClass: "attachment-files" },
            _vm._l(_vm.customFileList.downloadlist, function(item, index) {
              return _c("li", { key: index }, [
                _c("a", { attrs: { href: item.url, target: "_blank" } }, [
                  _c("span", [_vm._v(_vm._s(item.name))])
                ]),
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/information/icon_close.png"),
                    alt: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.onRemoveFile(index)
                    }
                  }
                })
              ])
            }),
            0
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("mt-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")]),
          _c(
            "mt-button",
            {
              attrs: { loading: _vm.submiting, type: "primary" },
              on: { click: _vm.onSubmit }
            },
            [_vm._v(_vm._s(_vm.isEdit ? "更新" : "提交"))]
          )
        ],
        1
      ),
      _c(
        "mt-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.daysVisible,
            callback: function($$v) {
              _vm.daysVisible = $$v
            },
            expression: "daysVisible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "toolbar" },
            [
              _c(
                "mt-button",
                { attrs: { size: "small" }, on: { click: _vm.onClose } },
                [_vm._v("取消")]
              ),
              _c(
                "mt-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onConfirmDays }
                },
                [_vm._v("确定")]
              )
            ],
            1
          ),
          _c("mt-picker", {
            attrs: { showToolbar: true, slots: _vm.daysSlots },
            on: { change: _vm.onDaysChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }